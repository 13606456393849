import RootRoute from './routes/index.js';
function App() {
  return (
    <div>
      <RootRoute/>
    </div>
  );
}

export default App;

