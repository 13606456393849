import "./ContactUsButton.css";
//import github from "../assets/github.svg";

const ContactUsButton = (props) => {
  return (
    <div className="flex-container-5">
      {/*
      <a href="https://github.com/in-seo/univcert" target="_blank" rel="noopener noreferrer">
        <img className="github" alt="github" src={github} />
      </a>
      */}
      <a href="https://salt-impala-0d7.notion.site/API-4967b300cbcf4b629ab6a689a2dcefe7?pvs=4" target="_blank" rel="noopener noreferrer">
        <button className="contact-button">Contact Us</button>
      </a>
    </div>
  );
}

export default ContactUsButton;
